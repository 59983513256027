






















import {defineComponent, PropType, reactive, toRef} from '@vue/composition-api';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import AssistedGradingWritten from '@/common/components/AssistedGradingWritten.vue';
import Task from '@/tasks/models/Task';
import {TaskType} from '@/tasks/types/TaskType';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import VueApexCharts from 'vue-apexcharts/dist/vue-apexcharts';
import {useBeerPlotWavelength} from '@/tasks/composables/beerPlotWavelength';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';

interface TableRow {
  lambda: number | null;
  abs: number | null;
}

export default defineComponent({
  name: 'TAMUBeersPreLabQ4',
  components: {
    CalculationInput,
    StembleLatex,
    AssistedGradingWritten,
    ApexChart: VueApexCharts,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        lambdaMax: null,
      },
    };
  },
  props: {
    task: {
      type: Object as PropType<Task<TaskType.DynamicQuestion>>,
      required: true,
    },
    isMarking: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  setup(props) {
    const inputs = reactive({
      rows: [
        {lambda: 340, abs: 0.1592},
        {lambda: 350, abs: 0.0744},
        {lambda: 360, abs: 0.0367},
        {lambda: 370, abs: 0.0191},
        {lambda: 380, abs: 0.0167},
        {lambda: 390, abs: 0.0201},
        {lambda: 400, abs: 0.0266},
        {lambda: 410, abs: 0.0212},
        {lambda: 420, abs: 0.009},
        {lambda: 430, abs: 0.0057},
        {lambda: 440, abs: 0.0084},
        {lambda: 450, abs: 0.0153},
        {lambda: 460, abs: 0.0215},
        {lambda: 470, abs: 0.034},
        {lambda: 480, abs: 0.043},
        {lambda: 490, abs: 0.0551},
        {lambda: 500, abs: 0.0639},
        {lambda: 510, abs: 0.072},
        {lambda: 520, abs: 0.0769},
        {lambda: 530, abs: 0.0836},
        {lambda: 540, abs: 0.1049},
        {lambda: 550, abs: 0.1419},
        {lambda: 560, abs: 0.202},
        {lambda: 570, abs: 0.2785},
        {lambda: 580, abs: 0.3688},
        {lambda: 590, abs: 0.4836},
        {lambda: 600, abs: 0.7069},
        {lambda: 610, abs: 0.9419},
        {lambda: 620, abs: 1.082},
        {lambda: 630, abs: 1.074},
        {lambda: 640, abs: 1.1188},
        {lambda: 650, abs: 1.3025},
        {lambda: 660, abs: 1.531},
        {lambda: 670, abs: 1.7201},
        {lambda: 680, abs: 1.5323},
        {lambda: 690, abs: 0.2341},
        {lambda: 700, abs: 0.3419},
        {lambda: 710, abs: 0.1279},
        {lambda: 720, abs: 0.0561},
        {lambda: 730, abs: 0.0267},
        {lambda: 750, abs: 0.0056},
        {lambda: 770, abs: 0.0016},
        {lambda: 790, abs: 0.0051},
        {lambda: 810, abs: 0.007},
        {lambda: 830, abs: 0.0076},
        {lambda: 850, abs: 0.0083},
        {lambda: 870, abs: 0.0083},
        {lambda: 890, abs: 0.0083},
        {lambda: 910, abs: 0.0083},
        {lambda: 930, abs: 0.0083},
      ] as TableRow[],
    });
    const rows = toRef(inputs, 'rows');
    const {colDefs, apexSeries, apexOptions} = useBeerPlotWavelength({
      labels: {abs: 'Absorbance', lambda: 'Wavelength (nm)'},
      x: 'lambda',
      y: 'abs',
      rows,
      isMarking: props.isMarking,
      minX: 340,
      maxX: null,
      minY: 0.0,
      maxY: null,
    });

    return {
      calibrationCurveColDefs: colDefs,
      rows,
      inputs,
      apexOptions,
      apexSeries,
    };
  },
});
